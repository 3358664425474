// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
 
 .nopad {
	padding-left: 0px;
 }
 
 .spacer{
	margin-top: 5px;
	margin-bottom: 40px;
 }

  .spacersmall{
	margin-bottom: 20px;
 }
 
 .spacerxsmall{
	margin-bottom: 10px;
 }

 .functie {
	font-size: 80%;
 }
 
 .row {
		margin-left: 0px;
 }